.tms-primary-btn {
  border-radius: 8px;
  background: var(--greenthemeMainColor, #1d1f2e) !important;
  color: var(--white, #fff);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-variant: all-small-caps;
  border: 1px solid var(--greenthemeMainColor) !important;
  width: 100%;
  padding: 10px 16px;
  transition: 0.3s ease-in;
  &.red{
    background: var(--redthemeMainColor, #1d1f2e) !important;
    border: 1px solid var(--redthemeMainColor) !important;
  }
}
.tms-primary-btn:hover {
  background: #fff;
  color: #1d1f2e;
}
