.division-details-main {
  .details-row {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    .col-head {
      width: 300px !important;
      background-color: #d2e3ef;
      color: #000 !important;
      font-size: 14px;
    }
    .dynamic-html {
      p {
        margin-bottom: 0;
      }
    }
    .col-md-6 {
      border: 1px solid #4d5d6b;
      width: calc(100% - 300px);
      font-size: 14px;
      padding: 5px;
      text-transform: capitalize;
    }
    .revision-head {
      background: #d8dfe5 !important;
      font-size: 16px;
      color: #000 !important;
    }
  }
}
