// Modal css start
.add-department-modal-main {
  .modal-dialog {
    .modal-content {
      border-radius: 8px;
      background: var(--white, #fff);
      .add-department-modal-header {
        border-bottom: 1px solid #f3f1f4;
        background: var(--bg-2, #f8f8fa);
        padding: 16px 24px;
        .add-department-modal-title {
          color: var(--black, #131313);
          font-family: "Montserrat";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .btn-close {
          background-size: 14px;
        }
      }
      .add-department-modal-body {
        background: #fff;
        padding: 24px;
        .add-department-modal-content-text {
          color: #4d5d6b;
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .form-select {
          border-radius: 8px;
          background-color: var(--background-2, #f8f8fa);
          border: none;
          padding: 12px 16px;
          &[aria-label],
          option {
            color: #4d5d6b;
            font-family: "Montserrat";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .custom-text-area {
          border-radius: 8px;
          background: var(--background-2, #f8f8fa);
          color: var(--text-color, #4d5d6b);
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border: none;
          &:focus {
            box-shadow: none;
          }
        }
        .back-icon-btn-main {
          border-radius: 8px;
          background: #f8f8fa;
          padding: 9px 16px;
          color: var(--text-color, #4d5d6b);
          font-family: "Montserrat";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border: 1px solid #f8f8fa;
        }
        .theme-btn-main {
          border-radius: 8px;
          background: var(--1-st-color, #22cdab);
          box-shadow: 0px 8px 24px 0px rgba(186, 218, 236, 0.5);
          color: var(--white-color, #fff);
          font-family: "Montserrat";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border: none;
        }
      }
    }
  }
}
// Modal css end
