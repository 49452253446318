@import url("../../../assets/css/dropdown.scss");
@import url("../../../assets/css/modal.scss");
@import url("../../../assets/css/table.scss");

.admin-popular-main {
  .search-input-main {
    background: #f8f8fa !important;
    border: 1px solid #f8f8fa !important;
    font-size: 12px !important;
    color: var(--black, #131313) !important;
    font-family: "Montserrat" !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;

    &::placeholder {
      color: var(--black, #131313) !important;
      font-family: "Montserrat" !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: normal !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
  font-family: "Montserrat";

  a {
    text-decoration: none;
    color: black;
  }

  .content-header-main {
    color: var(--black, #131313);
    font-family: "Montserrat";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    margin-bottom: 0px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
  }

  .action-btn-main {
    border-radius: 4px;
    background: #9747ff;
    padding: 8px 16px;
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .product-list-header-flex {
    min-width: 130px;
    .custom-status-select {
      width: 100%;
      text-transform: capitalize !important;
      border-radius: 8px;
      background-color: var(--background-2, #f8f8fa);
      border: 1px solid #f8f8fa;
      color: var(--text-color, #4d5d6b);
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 12px;
      &:focus {
        box-shadow: none;
      }
    }
  }
}
.add-department-modal-main {
  .sop-custom-form-control {
    border: none;
    color: #4d5d6b !important;
    font-size: 0.7rem !important;
    border-radius: 8px 0px 0px 8px !important;
    background: #f8f8fa !important;
    letter-spacing: 0.12px;
    outline: none;
    font-family: "Montserrat" !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &:focus {
      box-shadow: none;
    }
  }
  .sop-custom-form-select {
    .css-13cymwt-control,
    .css-1nmdiq5-menu,
    .css-1n6sfyn-MenuList,
    .css-t3ipsp-control {
      border: none;
      color: #4d5d6b !important;
      font-size: 0.7rem;
      letter-spacing: 0.12px;
      width: 100%;
      border-radius: 8px !important;
      background: var(--bg-2, #f8f8fa);
      outline: none;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &:focus {
        box-shadow: none;
      }
    }
    .css-t3ipsp-control {
      box-shadow: none;
    }
  }
  .form-group {
    .employee-custom-form-control {
      border: none;
      color: #4d5d6b !important;
      font-size: 0.7rem !important;
      border-radius: 8px !important;
      background: #f8f8fa !important;
      letter-spacing: 0.12px;
      outline: none;
      font-family: "Montserrat" !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.user-management-table-main {
  .table {
    width: 1400px;
    overflow-x: scroll;
    .table-header-flex-main {
      display: flex !important;
      align-items: center;
      gap: 20px;
    }
  }
}
.custom-react-select {
  width: 220px;
}

.record-status-form-control {
  border: none;
  color: #4d5d6b !important;
  font-size: 12px !important;
  background: #f8f8fa !important;
  letter-spacing: 0.12px;
  outline: none;
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  &:focus {
    box-shadow: none !important;
  }
}
