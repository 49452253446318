.addidcmatrix-main{
    .addidcmatrix-discpline{
        background-color: white;
        width:60%;
        padding: 20px;
        border-radius: 20px;

    }
    .workflow-head{
        font-size:16px;
        font-weight: 700;
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 43px;
        height: 23px;
      }
      
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      
      input:checked + .slider {
        background-color: #22cdab;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px #22cdab;
      }
      
      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
      
      .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
      
}