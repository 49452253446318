.reset-password-main{
    background-color: #f8f8fa;
    display: flex;
    justify-content: center;
    margin-top:100px;
    height:100vh;

    .reset-password-box{
       
        margin-top:100px;

        background-color: white;
        border-radius: 10px;
        padding: 30px;
        width:400px;
        height:400px;
    }
}