@import url("../../../assets/css/dropdown.scss");
@import url("../../../assets/css/modal.scss");
@import url("../../../assets/css/table.scss");

.admin-popular-main {
  font-family: "Montserrat";

  a {
    text-decoration: none;
    color: black;
  }

  .content-header-main {
    color: var(--black, #131313);
    font-family: "Montserrat";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    margin-bottom: 0px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
  }

  .search-input-main {
    background: #f8f8fa !important;
    border: 1px solid #f8f8fa !important;
    font-size: 12px !important;
    color: var(--black, #131313) !important;
    font-family: "Montserrat" !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;

    &::placeholder {
      color: var(--black, #131313) !important;
      font-family: "Montserrat" !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: normal !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}

.dotted-line-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dotted-line {
  flex: 1;
  border: none;
  border-top: 3px dotted #000;
  height: 0;
  margin: 15px;
}
.image-description {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.dotted-line-container {
  img {
    padding: 3%;
    padding-bottom: 0%;
  }
}
.image-description .role {
  color: #22cdab;
}
.custom-table-body {
  td {
    text-align: center;
    vertical-align: middle;
  }
}
.custom-table {
  tr,
  th,
  td {
    border: none;
  }
}

input[type="checkbox"]:checked {
  border: 2px solid #22cdab;
}

input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: white;
  height: 16px;
  width: 16px;
  border-radius: 5px;
  border: 1px solid black;
}

input[type="checkbox"]:after {
  content: " ";
  position: relative;
  left: 40%;
  top: 20%;
  width: 15%;
  height: 40%;
  border: solid #22cdab;
  border-width: 0 2px 2px 0;
  transform: rotate(50deg);
  display: none;
}

input[type="checkbox"]:checked:after {
  display: block;
}

.operation-text {
  text-transform: capitalize;
}

.service-table-head {
  tr {
    .head-operations {
      text-align: center;
    }
  }
}

.role-delete-model {
  .workflow-custom-formgroup {
    .form-select {
      border-radius: 0px !important;
    }
    .custom-border {
      border-left: 1px solid;
    }
  }
  .form-group {
    .form-label {
      color: #131313;
      font-family: "Montserrat";
      font-size: 0.7rem;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      .required-star {
        color: #f00;
      }
    }
    .input-element {
      border: 0.5px solid #4d5d6b;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .content {
    .table-main {
      padding: 10px 0;
      margin-top: 10px;
    }
  }
}
