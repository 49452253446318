.pagination {
  gap: 0.5em;
  .page-link {
    border-radius: 8px;
    border: none;
  }
  .page-item.active .page-link {
    background: var(--greenthemeMainColor);
    &.red {
      background: var(--redthemeMainColor);
    }
    color: #fff;
  }
}
.pagination-goto {
  text-transform: unset;
  .right-next {
    cursor: pointer;
    border-radius: 50px !important;

    background: var(--greenthemeMainColor, #1d1f2e);
    color: var(--white-color, #fff);
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px !important;

    &.red {
      background: var(--redthemeMainColor, #1d1f2e);
    }

    &:hover {
      background: grey;
      border: 1px solid #000;
    }
  }
}
