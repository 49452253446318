@import url("../../../../assets/css/dropdown.scss");
@import url("../../../../assets/css/modal.scss");
@import url("../../../../assets/css/table.scss");

.profile-master-main {
  .admin-popular-main {
    font-family: "Montserrat";

    .custom-react-select {
      .css-13cymwt-control {
        border-radius: 8px;
        background-color: var(--background-2, #f8f8fa);
        border: 1px solid #f8f8fa;
        color: var(--text-color, #4d5d6b);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    a {
      text-decoration: none;
      color: black;
    }

    .content-header-main {
      color: #131313;
      font-family: "Montserrat";
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 600;
      line-height: 160%;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1em;
    }
  }
  .add-sop-card-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0px;

    .add-sop-card-main {
      border-radius: 8px;
      background: #fff;
      padding: 24px;
      width: 100%;
      .upcoming-schedule-text-main {
        color: #131313;
        font-family: "Montserrat";
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .profile-certificate-card-main {
        border-radius: 8px;
        border: 0.5px solid var(--background-1, #eef0ff);
        background: var(--white-color, #fff);
        box-shadow: 0px 10px 20px 0px rgba(41, 45, 50, 0.05);
        padding: 18px;
        font-family: "Montserrat";
        font-style: normal;
        margin-bottom: 20px;

        .profile-certificate-card-header {
          color: #131313;
          font-size: 16px;
          font-weight: 600;
          line-height: 30px;
        }
        .profile-certificate-card-subheader {
          color: #131313;
          font-family: "Montserrat";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .certificate-card-content-main {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .content-header-main {
            color: #131313;
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
          }
          .content-para-main {
            color: #4d5d6b;
            font-size: 12px;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      .profile-certificate-edit-main {
        border-radius: 8px;
        cursor: pointer;
        background: var(--background-2, #f8f8fa);
        padding: 5px 16px;
        .edit-icon-main {
          width: 24px;
          height: 24px;
          margin-right: 10px;
          img {
            height: 100%;
          }
        }
      }
      .personal-information-content-main {
        font-family: "Montserrat";
        font-style: normal;
        margin: 20px 0px 0px;

        .perinfo-content-header {
          color: #4d5d6b;
          font-size: 14px;
          font-weight: 400;
          line-height: 25px;
          text-transform: capitalize;
        }
        .perinfo-content-para {
          color: #131313;
          font-size: 14px;
          font-weight: 600;
          line-height: normal;
          .attachment-icon-main {
            width: 20px;
            height: 20px;
            img {
              height: 20px;
            }
          }
        }
      }
    }
    .custom-tabs {
      border: none;
      padding-bottom: 20px;
      border-bottom: 0.5px solid #4d5d6b;
      &:focus,
      &:focus-visible {
        border: none;
        outline: none;
        border-width: 0;
      }
      .nav-item.active,
      .nav-item:hover,
      .nav-item:focus,
      .nav-item:focus-visible {
        border: none;
        outline: none;
      }
      .nav-link {
        color: #131313;
        font-family: "Montserrat";
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
      }
      .nav-link.active,
      .nav-link:hover,
      .nav-link:focus,
      .nav-link:focus-visible {
        color: var(--white);
        border-radius: 8px;
        background-color: var(--greenthemeMainColor);
        border: none !important;
        box-shadow: none;
        outline: none;
      }
      &.red {
        background-color: var(--redthemeMainColor);
      }
    }
    .form-group {
      .form-label {
        color: #131313;
        font-family: "Montserrat";
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        .required-star {
          color: #f00;
        }
      }
      .input-element {
        border: 0.5px solid #4d5d6b;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .sop-custom-form-date {
      border: 0.5px solid #4d5d6b;
      color: #4d5d6b !important;
      font-size: 0.7rem;
      letter-spacing: 0.12px;
      border-radius: 8px !important;
      background: var(--bg-2, #f8f8fa);
      outline: none;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 11px 16px;
      width: 100%;
      &:focus {
        box-shadow: none;
      }
    }
    .sop-custom-form-control {
      border: 0.5px solid #4d5d6b;
      color: #4d5d6b !important;
      font-size: 0.7rem !important;
      border-radius: 8px 0px 0px 8px !important;
      background: #f8f8fa !important;
      letter-spacing: 0.12px;
      outline: none;
      font-family: "Montserrat" !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &:focus {
        box-shadow: none;
      }
    }
    .sop-custom-form-select {
      .css-13cymwt-control {
        border: 0.5px solid #4d5d6b;
        color: #4d5d6b !important;
        font-size: 0.7rem;
        letter-spacing: 0.12px;
        width: 100%;
        border-radius: 8px !important;
        background: var(--bg-2, #f8f8fa);
        outline: none;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .custom-checkbox {
      .custom-checkbox-label {
        color: #131313;
        font-family: "Montserrat";
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        margin-left: 20px;
      }
    }
    .custome-file-upload {
      color: #131313;
      font-family: "Montserrat";
      font-size: 0.7rem;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      border: none;
      &:focus {
        box-shadow: none;
      }
    }
    .custome-file-upload::file-selector-button {
      border-radius: 5px;
      background: #9747ff;
      padding: 8px 16px;
      color: #fff;
      font-family: "Inter";
      font-size: 0.7rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.12px;
      border: none;
      &:hover {
        color: #131313 !important;
      }
    }

    .back-icon-btn-main {
      border-radius: 8px;
      background: #f8f8fa;
      padding: 9px 16px;
      color: var(--text-color, #4d5d6b);
      font-family: "Montserrat";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border: 1px solid #f8f8fa;
    }
    .theme-btn-main {
      border-radius: 8px;
      background: #22cdab;
      box-shadow: 0px 8px 24px 0px rgba(186, 218, 236, 0.5);
      color: var(--white-color, #fff);
      font-family: "Montserrat";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border: 1px solid #22cdab;
    }
    .profile-image-main {
      width: 96px;
      height: 96px;
      img {
        height: 100%;
      }
    }
    .profile-image-header-main {
      font-family: "Montserrat";
      font-style: normal;
      line-height: normal;
      .profile-header_main {
        color: #131313;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: capitalize;
      }
      .profile-content-main {
        color: #4d5d6b;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .profile-option-icon-main {
      border-radius: 8px;
      background: var(--2nd-color, #1d1f2e);
      box-shadow: 0px 8px 24px 0px rgba(186, 218, 236, 0.5);
      padding: 7px;
      display: inline-block;
    }
  }
  .border-group-text {
    border: 0.5px solid #4d5d6b;
    border-left: none;
  }

  .schedule-view-icon-btn {
    border-radius: 8px;
    background: #f8f8fa;
    padding: 7px 16px;
    border: 1px solid #f8f8fa;
    .schedule-view-icon-main {
      width: 24px;
      height: 24px;
      img {
        height: 100%;
      }
    }
    &:hover {
      background: #1d1f2e;
      border: 1px solid #1d1f2e;
      .schedule-view-icon-main {
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
  .schedule-view-active-main {
    background: #1d1f2e;
    border: 1px solid #1d1f2e;
    .schedule-view-icon-main {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
  .upcomig-schedule-main {
    background: #fff;
    border-radius: 8px;
    border: 0.5px solid #4d5d6b;
    padding: 16px;
    .upcoming-schedule-tabs-main {
      padding-left: 12px;
      border-left: 2px solid #9747ff;
      .schedule-tabs-header-main {
        color: #131313;
        font-family: "Montserrat";
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
      }
      .schedule-tab-main {
        border-radius: 50px;
        background: rgba(19, 19, 19, 0.05);
        padding: 4px 7px;
        display: inline;
        span {
          color: #131313;
          font-family: "Montserrat";
          font-size: 0.7rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .time-schedule-card-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      gap: 30px;
      .time-schedule-width-header-main {
        color: #4d5d6b;
        font-family: "Montserrat";
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .time-schedule-width-content-main {
        color: #131313;
        font-family: "Montserrat";
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .trainer-header-main {
      color: #131313;
      font-family: "Montserrat";
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .profile-calendar-main {
    border-radius: 11.884px;
    background: #fff;
    box-shadow: 0px 0px 0px 1.4855072498321533px #e4e4e4;
    padding: 20px 10px;
    margin-top: 17px;
    .react-calendar {
      border: none;
      .react-calendar__month-view__weekdays__weekday {
        text-align: center;
        abbr {
          text-decoration: none;
          font-size: 0.9rem;
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
      .react-calendar__navigation__next2-button,
      .react-calendar__navigation__prev2-button {
        display: none;
      }
      abbr {
        text-decoration: none;
        font-size: 0.9rem;
      }
      .react-calendar__month-view__days__day {
        color: #a6a6a6;
        font-family: "Montserrat";
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 500;
        line-height: 23.768px;
        border: none;
        background: none;
        padding: 10px 0px;
      }
      .react-calendar__tile--active,
      .react-calendar__tile:enabled:hover {
        border-radius: 7.428px;
        background: #22cdab;
        color: #fff;
        font-family: "Montserrat";
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 500;
        line-height: 23.768px;
      }
    }
  }
  .trainer_name {
    text-transform: capitalize;
  }
}
