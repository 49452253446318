@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fahkwang:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --redthemePrimaryColor: #e65c4f;
  --redthemeBackground: #f5f2ed;
  --redthemeMainColor: #e65c4f;
  --redthemeSideBackground: #131313;
  --white: #ffffff;
  --greenthemePrimaryColor: #22cdab;
  --greenthemeBackground: #F5F2ED;
  --greenthemeMainColor: #22cdab;
  --greenthemeSideBackground: #1d1f2e;
}

.error {
  font-size: 12px;
  color: red;
  margin-left: 5px;
}

.no-data-found-main {
  font-size: 18px;
  color: #22cdab;
  font-weight: 500;
  text-align: center;
  margin-top: 80px;
}

.custom-pagination-main {
  .card-inner {
    padding: 1.5rem;
    .nk-block-between-md {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .pagination {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      .page-item {
        cursor: pointer;
      }
      .page-link {
        border-radius: 50px;
        background: var(--background-2, #f8f8fa);
        color: #dbdfea;
        pointer-events: none;
        border: none;
        cursor: pointer;
        position: relative;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        color: var(--text-color, #4d5d6b);
        font-family: "Montserrat";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 10px;
        height: 32px;
        width: 32px;
        text-align: center;
      }
    }
  }
}

.theme-btn-main {
  width: 30% !important;
}
