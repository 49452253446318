.heading-sticky {
  display: flex;
  justify-content: space-between;
  .heading-main {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    font-variant: small-caps;
    color: #000000;
  }
  .close-icon {
    cursor: pointer;
  }
}

@media only screen and (max-width) {
  .heading-sticky {
    position: sticky;
    top: 0.2em;
    z-index: 5;
    backdrop-filter: blur(5px);
    border-radius: 10px;
  }
}
