.header-main {
  position: fixed;
  width: calc(100% - 250px);
  z-index: 999;
  background: #fff;

  .nk-header {
    background-color: transparent !important;
    border: unset;
    box-shadow: unset;
    backdrop-filter: blur(15px);
    padding-block: 1.5rem;

    .nk-header-wrap {
      margin: 0 1rem;
    }

    .avatar-wrapper {
      background: #060644;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      padding: 0.7em;

      img {
        object-fit: contain;
        width: 90%;
      }
    }
  }

  .notification-icon-main {
    border-radius: 8px;
    background: var(--background-2, #f8f8fa);
    padding: 17px 10px;
    display: inline-block;
  }

  .dropdown-main {
    .dropdown-content-main {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .header-profile-icon-main {
      height: 36px;
      width: 36px;
      margin-right: 12px;
      img {
        height: 100%;
      }
    }
  }

  .sign-out-btn {
    font-size: 15px;
    color: #808080;
    cursor: pointer;
    font-weight: 600;
  }

  .sign-out-btn:hover {
    color: #0f93c3;
  }

  .plant-name {
    font-weight: bold;
    color: #0f93c3;
    margin-left: 10px;
    cursor: pointer;
  }

  .store-notification-main {
    background: #ffffff;
    border-radius: 8px;
    width: 350px !important;
    max-width: 350px !important;

    .store-notification-header-main {
      padding: 8px 12px;
      border-bottom: 1px solid #000;
    }

    .notification-primary-header {
      font-weight: 700;
      font-size: 16px;
      line-height: 15px;
      color: #171719;
      margin: 20px;
    }

    .store-notification-content-main {
      background: #ffffff;
      border-bottom: 0.2px solid #333333;
      box-shadow: 0px 8px 50px rgba(6, 6, 68, 0.05);
      padding: 8px;
      font-family: "Montserrat";
      font-style: normal;
      cursor: pointer;

      h2 {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #171719;
        cursor: pointer;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #333333;
      }
    }
  }
}

.toggle-slide-right {
  transition: all 0.5s ease-in-out;
  background-color: #fff;
  right: 0;
  z-index: 999;
  box-shadow: -20px 20px 30px 0px rgba(0, 0, 0, 0.25);
  .notifications {
    .notifications-head {
      border: 0.5px solid rgba(0, 0, 0, 0.25);
      background: #fff;
      padding: 8px 16px;
      h6 {
        color: #171719;
        font-family: "Roboto";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        margin-top: 4px;
      }
      .notification-close {
        cursor: pointer;
      }
    }
    .notifications-content {
      overflow: auto;
      padding: 8px 16px;
      max-height: 100vh;
      .each-notification {
        padding: 8px;
        .notification-title {
          color: #171719;
          font-family: "Roboto";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
        }

        .notification-info {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          align-self: stretch;
          overflow: hidden;
          color: rgba(51, 51, 51, 0.7);
          text-overflow: ellipsis;
          font-family: "Roboto";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
        .notification-time {
          color: rgba(23, 23, 25, 0.3);
          font-family: "Poppins";
          font-size: 12px;
          font-style: italic;
          font-weight: 400;
          line-height: 18px; /* 150% */
        }
      }
    }
  }
}
.slide-right-open {
  transform: translate(0, 10px);
}
.slide-right-close {
  transform: translate(100%, 10px);
}

@media (max-width: 1200px) {
  .header-main {
    width: 100%;
  }
}
