.canval-names {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 11px;
  text-align: center;
  font-weight: 500;
  .canval-p {
    text-align: center;
    margin-top: 8px;
    width: 200px !important;
  }
}
.policies-form {
  background-color: #fff;
}

.faq-table-main {
  border-radius: 8px;
  background-color: #fff;
  padding: 20px;
}
.policy-update_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    background-color: #22cdab;
    border: none;
  }
  button:hover {
    background-color: #1a9e8b;
  }
}
.enable_select {
  background-color: red;
}
